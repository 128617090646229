var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation-drawer",
      attrs: { permanent: _vm.$vuetify.breakpoint.mdAndUp, "hide-overlay": "" },
      model: {
        value: _vm.isDrawerShownModel,
        callback: function($$v) {
          _vm.isDrawerShownModel = $$v
        },
        expression: "isDrawerShownModel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "navigation-drawer__header" },
        [
          _c(
            "div",
            { staticClass: "pl-2" },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "avatar",
                  attrs: { width: 96, tile: "" },
                  on: { click: _vm.redirectToLivePage }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/images/astro-logo.png"),
                      contain: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isDrawerShown && _vm.isMobile
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.isDrawerShownModel = false
                    }
                  }
                },
                [_c("v-icon", { staticClass: "icon" }, [_vm._v("close")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "navigation-drawer__content" },
        [
          _c(
            "v-list-item",
            { attrs: { ripple: false, link: "", to: { name: "live" } } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { staticClass: "icon" }, [
                    _vm._v("online_prediction")
                  ])
                ],
                1
              ),
              _c("v-list-item-content", [
                _vm._v(" " + _vm._s(_vm.$t("menu.live")) + " ")
              ])
            ],
            1
          ),
          _vm.isReportsViewer
            ? _c(
                "v-list-group",
                {
                  class: { "list-group--active": _vm.isReportsTab },
                  attrs: { value: _vm.isReportsTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", { staticClass: "icon" }, [
                                  _vm._v("bar_chart")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { attrs: { "data-intercom-target": "reports" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("menu.reports")) + " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2755797382
                  )
                },
                [
                  _vm.isPerformanceViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            ripple: false,
                            link: "",
                            to: { name: "performances" }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            {
                              attrs: { "data-intercom-target": "performance" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.performancesTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinanceViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            ripple: false,
                            link: "",
                            to: { name: "finance" }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { attrs: { "data-intercom-target": "finance" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.financeTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isQualityViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/quality")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { attrs: { "data-intercom-target": "quality" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.qualityTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinanceViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/sales")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { attrs: { "data-intercom-target": "items_sold" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("reportings.salesTab.label")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isRefundsViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/refunds")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { attrs: { "data-intercom-target": "refunds" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.refundsTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isOrdersViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl(
                                "/r/reports/orders-details"
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            {
                              attrs: {
                                "data-intercom-target": "orders_details"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.ordersDetailsTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isHygieneViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/hygiene")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { attrs: { "data-intercom-target": "hygiene" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.hygieneTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDeliveriesPerformance
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl(
                                "/r/reports/delivery-control"
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            {
                              attrs: {
                                "data-intercom-target": "delivery-control"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "reportings.deliveryControlTab.label"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.isInvoicesViewer || _vm.hasInvoicesBeta
            ? _c(
                "v-list-item",
                {
                  class: { "v-list-item--active": _vm.isInvoicesTab },
                  attrs: { ripple: false, value: _vm.isInvoicesTab, link: "" },
                  on: { click: _vm.handleInvoicesRedirection }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("description")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(" " + _vm._s(_vm.$t("menu.invoices")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isMenuManagerViewer
            ? _c(
                "v-list-group",
                {
                  class: { "list-group--active": _vm.isMenuManagerTab },
                  attrs: { value: _vm.isMenuManagerTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", { staticClass: "icon" }, [
                                  _vm._v("restaurant_menu")
                                ])
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("menu.menuManager")) + " "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    542031277
                  )
                },
                [
                  _vm.isMenuManagerTemplatesViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            ripple: false,
                            to: { path: "/menu-manager/menus" }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("menuManager.homepage.menus.title")
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuManagerRestaurantsViewer
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            ripple: false,
                            to: { path: "/menu-manager/restaurants" }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "menuManager.homepage.restaurants.title"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasItemLibraryAccess
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: { click: _vm.handleItemLibraryRedirection }
                        },
                        [
                          _c("v-list-item-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "menuManager.homepage.itemLibrary.title"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.isOpeningsViewer
            ? _c(
                "v-list-item",
                {
                  attrs: {
                    ripple: false,
                    link: "",
                    to: { name: "openingHours" }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("schedule")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(" " + _vm._s(_vm.$t("menu.openingHours")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.hasKitchensAccess
            ? _c(
                "v-list-item",
                {
                  attrs: { ripple: false, link: "" },
                  on: {
                    click: function($event) {
                      return _vm.navigateToUrl("/r/kitchens")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("location_city")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(" " + _vm._s(_vm.$t("menu.kitchens")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.hasSupplyAccess
            ? _c(
                "v-list-group",
                {
                  class: { "list-group--active": _vm.isSupplierTab },
                  attrs: { value: _vm.isSupplierTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      color: "primary",
                                      dot: "",
                                      value:
                                        !!_vm.totalOrderingReminder ||
                                        !!_vm.totalDeliveriesReminder
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "icon" }, [
                                      _vm._v("local_shipping")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { attrs: { "data-intercom-target": "supply" } },
                              [_vm._v(" " + _vm._s(_vm.$t("supply")) + " ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    812844080
                  )
                },
                [
                  _vm.hasSuppliersAccess
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/suppliers")
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "sub-list-item" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("menu.orders")))
                              ]),
                              !!_vm.totalOrderingReminder
                                ? _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.totalOrderingReminder > 99
                                            ? "99+"
                                            : _vm.totalOrderingReminder
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasSupplyAccess
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/orders")
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "sub-list-item" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("menu.deliveries")))
                              ]),
                              !!_vm.totalDeliveriesReminder
                                ? _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.totalDeliveriesReminder > 99
                                            ? "99+"
                                            : _vm.totalDeliveriesReminder
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasSupplyMailbox
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/mailbox")
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _vm._v(" " + _vm._s(_vm.$t("mailbox")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "navigation-drawer__footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "support-btn",
              attrs: { id: "support", ripple: false, text: "", block: "" }
            },
            [
              _c("v-icon", { staticClass: "icon" }, [
                _vm._v("contact_support")
              ]),
              _c("div", [_vm._v("Support")])
            ],
            1
          ),
          _c(
            "a",
            {
              attrs: { href: _vm.localizeDocumentationLink, target: "_blank" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "support-btn",
                  attrs: { ripple: false, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleDocumentationClick()
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "icon auto_stories" }),
                  _c("div", [_vm._v(_vm._s(_vm.$t("shared.seeDocumentation")))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c("v-icon", { attrs: { size: "30" } }, [
                _vm._v("account_circle")
              ]),
              _c("p", { staticClass: "user-email" }, [
                _vm._v(" " + _vm._s(_vm.userEmail) + " ")
              ]),
              _c("LogoutButton")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }