var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "text-subtitle-1",
      attrs: { color: "dark", icon: "", ripple: false, text: "" },
      on: { click: _vm.logout }
    },
    [_c("v-icon", [_vm._v("logout")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }