var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-layout" },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-app-bar",
            {
              attrs: { elevation: "0", fixed: "", "clipped-left": "", app: "" }
            },
            [
              _c(
                "v-app-bar-nav-icon",
                { on: { click: _vm.toggleDrawer } },
                [_c("v-icon", [_vm._v("menu")])],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "page-layout-wrapper" },
        [
          _c("SideBarNavigation", {
            attrs: { "is-drawer-shown": _vm.isDrawerShown },
            on: { "update:isDrawerShown": _vm.setIsDrawerShown }
          }),
          _c("v-main", [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }