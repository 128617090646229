import Keycloak from 'keycloak-js';

class Client {
    /**
     * Constructs a new instance of an authentication client.
     * @param options The options used to instanciate the authentication client.
     * @param options.url The URL of the Keycloak authentication service.
     * @param options.realm The realm to use for the authentication process.
     * @param options.clientId The application's client ID.
     */
    constructor({ url, realm, clientId }) {
        this.keycloakClient = new Keycloak({ url, realm, clientId });

        this.keycloakClient.onTokenExpired = () => {
            this.keycloakClient.updateToken(-1).then((isRefreshed) => {
                if (isRefreshed) {
                    this.updateUser();
                }
            });
        };

        this.keycloakClient.createLogoutUrl = () => {
            let logoutUrl =
                this.keycloakClient.endpoints.logout() +
                '?client_id=' +
                encodeURIComponent(this.keycloakClient.clientId) +
                '&post_logout_redirect_uri=' +
                encodeURIComponent(location.href.split('?')[0]); // Clear query-params to avoid entity too large

            if (this.keycloakClient.idToken) {
                logoutUrl += '&id_token_hint=' + encodeURIComponent(this.keycloakClient.idToken);
            }

            return logoutUrl;
        };
    }

    /**
     * The current authenticated user.
     */
    get user() {
        return this.keycloakClient.authenticated ? this.keycloakClient.userInfo || null : null;
    }

    /**
     * The current authenticated user's groups.
     */
    get groups() {
        return this.user ? this.user.groups : [];
    }

    /**
     * The current authenticated user's roles.
     */
    get roles() {
        return this.user ? this.user.realmRoles : [];
    }

    get userGroups() {
        let reg = new RegExp(`/(.+?)/groups/`);
        let matched = this.groups.filter((group) => {
            return group.match(reg);
        });
        let groups = [];
        matched.forEach((group) => {
            let splitted = group.split('/');
            groups.push(splitted[splitted.length - 1]);
        });
        return groups;
    }

    get userRoles() {
        const roles = this.user ? this.user.realmRoles : [];

        return roles ?? [];
    }

    getToken() {
        return this.keycloakClient.token;
    }

    /**
     * Asks the user to authenticate itself.
     */
    async signIn() {
        await this.keycloakClient.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
            responseMode: 'query',
        });
        await this.updateUser();

        const { email, sub } = this.keycloakClient.userInfo;
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'oneboard_basic_user_info',
                user_uuid: sub,
                user_email: email,
            });
        }
    }

    /**
     * Logs out the current user.
     * @param redirectUri An URI to use to redirect the user after the logout.
     */
    async logout(redirectUri) {
        await this.keycloakClient.logout({ redirectUri });
    }

    async refreshToken() {
        return this.keycloakClient.updateToken(-1).then(async (isRefreshed) => {
            if (isRefreshed) {
                await this.updateUser();
            }
        });
    }

    /**
     * Updates the internal current authenticated user info.
     */
    async updateUser() {
        try {
            await this.keycloakClient.loadUserInfo();
        } catch (_err) {
            throw new Error('Unable to retrieve user info');
        }
    }
}

export default {
    install: function (Vue) {
        Vue.prototype.$auth = new Client({
            url: process.env.VUE_APP_IAM_AUTH_SERVER_URL,
            clientId: process.env.VUE_APP_IAM_CLIENT_ID,
            realm: process.env.VUE_APP_IAM_REALM,
        });
    },
};
