var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageLayout",
    [
      _c("SnackbarAlert"),
      _c(
        "v-overlay",
        { attrs: { color: "transparent", value: _vm.isLoading, absolute: "" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary", size: "64" }
          })
        ],
        1
      ),
      !_vm.isLoading
        ? [
            _vm.activeKitchens.length && _vm.concepts.length
              ? _c("router-view")
              : _c(
                  "div",
                  { staticClass: "error-container" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mb-3",
                        attrs: { "x-large": "", color: "primary" }
                      },
                      [_vm._v(" fa-utensils ")]
                    ),
                    _vm.activeKitchens.length === 0
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.$t("shared.noKitchen")) + " ")
                        ])
                      : _vm._e(),
                    _vm.concepts.length === 0
                      ? _c("div", [
                          _vm._v(" " + _vm._s(_vm.$t("shared.noConcept")) + " ")
                        ])
                      : _vm._e(),
                    _c("div", [_vm._v(_vm._s(_vm.$t("shared.noRightSubText")))])
                  ],
                  1
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }