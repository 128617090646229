var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      class: _vm.snackbarClass,
      attrs: { top: _vm.top, bottom: _vm.bottom, timeout: _vm.timeout },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      color: _vm.color,
                      type: "button",
                      icon: "",
                      "aria-label": "Close alert"
                    },
                    on: { click: _vm.closeSnackbar }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("fa-solid fa-xmark ")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.showSnackbar,
        callback: function($$v) {
          _vm.showSnackbar = $$v
        },
        expression: "showSnackbar"
      }
    },
    [
      _c(
        "div",
        { class: _vm.contentClass },
        [
          _c("v-icon", { staticClass: "snackbar-alert__icon" }, [
            _vm._v(" " + _vm._s(_vm.icon) + " ")
          ]),
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "text-body-1 title" }, [
              _vm._v(_vm._s(_vm.message))
            ]),
            _vm.subText.length
              ? _c("p", { staticClass: "text-body-1" }, [
                  _vm._v(_vm._s(_vm.subText))
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }