import { render, staticRenderFns } from "./SideBarNavigation.vue?vue&type=template&id=b091ac1e&scoped=true&"
import script from "./SideBarNavigation.vue?vue&type=script&lang=js&"
export * from "./SideBarNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SideBarNavigation.vue?vue&type=style&index=0&id=b091ac1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b091ac1e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAvatar,VBadge,VBtn,VIcon,VImg,VList,VListGroup,VListItem,VListItemContent,VListItemIcon,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/workspace/app-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b091ac1e')) {
      api.createRecord('b091ac1e', component.options)
    } else {
      api.reload('b091ac1e', component.options)
    }
    module.hot.accept("./SideBarNavigation.vue?vue&type=template&id=b091ac1e&scoped=true&", function () {
      api.rerender('b091ac1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SideBarNavigation/SideBarNavigation.vue"
export default component.exports